@import "../settings/variables";

.country-select .flag {
	width: 16px;
	height: 11px;
	background: url("#{$img-dir}flags.png");
}
.country-select {
  .zw {
    background-position: 0 0;
  }
  .zm {
    background-position: -16px 0;
  }
  .za {
    background-position: 0 -11px;
  }
  .yt {
    background-position: -16px -11px;
  }
  .ye {
    background-position: -32px 0;
  }
  .ws {
    background-position: -32px -11px;
  }
  .wf {
    background-position: 0 -22px;
  }
  .vu {
    background-position: -32px -22px;
  }
  .vn {
    background-position: 0 -33px;
  }
  .vi {
    background-position: -16px -33px;
  }
  .vg {
    background-position: -32px -33px;
  }
  .ve {
    background-position: -48px 0;
  }
  .vc {
    background-position: -48px -11px;
  }
  .va {
    background-position: -48px -22px;
  }
  .uz {
    background-position: -48px -33px;
  }
  .uy {
    background-position: 0 -44px;
  }
  .us, .um {
    background-position: -16px -44px;
  }
  .ug {
    background-position: -32px -44px;
  }
  .ua {
    background-position: -48px -44px;
  }
  .tz {
    background-position: -64px 0;
  }
  .tw {
    background-position: -64px -11px;
  }
  .tv {
    background-position: -64px -22px;
  }
  .tt {
    background-position: -64px -33px;
  }
  .tr {
    background-position: -64px -44px;
  }
  .to {
    background-position: 0 -55px;
  }
  .tn {
    background-position: -16px -55px;
  }
  .tm {
    background-position: -32px -55px;
  }
  .tl {
    background-position: -48px -55px;
  }
  .tk {
    background-position: -64px -55px;
  }
  .tj {
    background-position: 0 -66px;
  }
  .th {
    background-position: -16px -66px;
  }
  .tg {
    background-position: -32px -66px;
  }
  .tf {
    background-position: -48px -66px;
  }
  .td {
    background-position: -64px -66px;
  }
  .tc {
    background-position: -80px 0;
  }
  .sz {
    background-position: -80px -11px;
  }
  .sy {
    background-position: -80px -22px;
  }
  .sx {
    background-position: -80px -33px;
  }
  .sv {
    background-position: -80px -44px;
  }
  .st {
    background-position: -80px -55px;
  }
  .ss {
    background-position: -80px -66px;
  }
  .sr {
    background-position: 0 -77px;
  }
  .so {
    background-position: -16px -77px;
  }
  .sn {
    background-position: -32px -77px;
  }
  .sm {
    background-position: -48px -77px;
  }
  .sl {
    background-position: -64px -77px;
  }
  .sk {
    background-position: -80px -77px;
  }
  .si {
    background-position: -96px 0;
  }
  .sh {
    background-position: -96px -11px;
  }
  .sg {
    background-position: -96px -22px;
  }
  .se {
    background-position: -96px -33px;
  }
  .sd {
    background-position: -96px -44px;
  }
  .sc {
    background-position: -96px -66px;
  }
  .sb {
    background-position: -96px -77px;
  }
  .sa {
    background-position: 0 -88px;
  }
  .rw {
    background-position: -16px -88px;
  }
  .ru {
    background-position: -32px -88px;
  }
  .rs {
    background-position: -48px -88px;
  }
  .ro {
    background-position: -64px -88px;
  }
  .qa {
    background-position: -80px -88px;
  }
  .py {
    background-position: -96px -88px;
  }
  .pw {
    background-position: 0 -99px;
  }
  .pt {
    background-position: -16px -99px;
  }
  .ps {
    background-position: -32px -99px;
  }
  .pr {
    background-position: -48px -99px;
  }
  .pn {
    background-position: -64px -99px;
  }
  .pm {
    background-position: -80px -99px;
  }
  .pl {
    background-position: -96px -99px;
  }
  .pk {
    background-position: -112px 0;
  }
  .ph {
    background-position: -112px -11px;
  }
  .pg {
    background-position: -112px -22px;
  }
  .pf {
    background-position: -112px -33px;
  }
  .pe {
    background-position: -112px -44px;
  }
  .pa {
    background-position: -112px -55px;
  }
  .om {
    background-position: -112px -66px;
  }
  .nz {
    background-position: -112px -77px;
  }
  .nu {
    background-position: -112px -88px;
  }
  .nr {
    background-position: -112px -99px;
  }
  .no, .bv, .sj {
    background-position: 0 -110px;
  }
  .nl {
    background-position: -16px -110px;
  }
  .ni {
    background-position: -32px -110px;
  }
  .ng {
    background-position: -48px -110px;
  }
  .nf {
    background-position: -64px -110px;
  }
  .ne {
    background-position: -80px -110px;
  }
  .nc {
    background-position: -96px -110px;
  }
  .na {
    background-position: -112px -110px;
  }
  .mz {
    background-position: -128px 0;
  }
  .my {
    background-position: -128px -11px;
  }
  .mx {
    background-position: -128px -22px;
  }
  .mw {
    background-position: -128px -33px;
  }
  .mv {
    background-position: -128px -44px;
  }
  .mu {
    background-position: -128px -55px;
  }
  .mt {
    background-position: -128px -66px;
  }
  .ms {
    background-position: -128px -77px;
  }
  .mr {
    background-position: -128px -88px;
  }
  .mq {
    background-position: -128px -99px;
  }
  .mp {
    background-position: -128px -110px;
  }
  .mo {
    background-position: 0 -121px;
  }
  .mn {
    background-position: -16px -121px;
  }
  .mm {
    background-position: -32px -121px;
  }
  .ml {
    background-position: -48px -121px;
  }
  .mk {
    background-position: -64px -121px;
  }
  .mh {
    background-position: -80px -121px;
  }
  .mg {
    background-position: -96px -121px;
  }
  .me {
    background-position: 0 -132px;
    height: 12px;
  }
  .md {
    background-position: -112px -121px;
  }
  .mc {
    background-position: -128px -121px;
  }
  .ma {
    background-position: -16px -132px;
  }
  .ly {
    background-position: -32px -132px;
  }
  .lv {
    background-position: -48px -132px;
  }
  .lu {
    background-position: -64px -132px;
  }
  .lt {
    background-position: -80px -132px;
  }
  .ls {
    background-position: -96px -132px;
  }
  .lr {
    background-position: -112px -132px;
  }
  .lk {
    background-position: -128px -132px;
  }
  .li {
    background-position: -144px 0;
  }
  .lc {
    background-position: -144px -11px;
  }
  .lb {
    background-position: -144px -22px;
  }
  .la {
    background-position: -144px -33px;
  }
  .kz {
    background-position: -144px -44px;
  }
  .ky {
    background-position: -144px -55px;
  }
  .kw {
    background-position: -144px -66px;
  }
  .kr {
    background-position: -144px -77px;
  }
  .kp {
    background-position: -144px -88px;
  }
  .kn {
    background-position: -144px -99px;
  }
  .km {
    background-position: -144px -110px;
  }
  .ki {
    background-position: -144px -121px;
  }
  .kh {
    background-position: -144px -132px;
  }
  .kg {
    background-position: 0 -144px;
  }
  .ke {
    background-position: -16px -144px;
  }
  .jp {
    background-position: -32px -144px;
  }
  .jo {
    background-position: -48px -144px;
  }
  .jm {
    background-position: -64px -144px;
  }
  .je {
    background-position: -80px -144px;
  }
  .it {
    background-position: -96px -144px;
  }
  .is {
    background-position: -112px -144px;
  }
  .ir {
    background-position: -128px -144px;
  }
  .iq {
    background-position: -144px -144px;
  }
  .io {
    background-position: -160px 0;
  }
  .in {
    background-position: -160px -11px;
  }
  .im {
    background-position: -160px -22px;
    height: 9px;
  }
  .il {
    background-position: -160px -31px;
  }
  .ie {
    background-position: -160px -42px;
  }
  .id {
    background-position: -160px -53px;
  }
  .hu {
    background-position: -160px -64px;
  }
  .ht {
    background-position: -160px -75px;
  }
  .hr {
    background-position: -160px -86px;
  }
  .hn {
    background-position: -160px -97px;
  }
  .hk {
    background-position: -160px -108px;
  }
  .gy {
    background-position: -160px -119px;
  }
  .gw {
    background-position: -160px -130px;
  }
  .gu {
    background-position: -160px -141px;
  }
  .gt {
    background-position: 0 -155px;
  }
  .gs {
    background-position: -16px -155px;
  }
  .gr {
    background-position: -32px -155px;
  }
  .gq {
    background-position: -48px -155px;
  }
  .gp {
    background-position: -64px -155px;
  }
  .gn {
    background-position: -80px -155px;
  }
  .gm {
    background-position: -96px -155px;
  }
  .gl {
    background-position: -112px -155px;
  }
  .gi {
    background-position: -128px -155px;
  }
  .gh {
    background-position: -144px -155px;
  }
  .gg {
    background-position: -160px -155px;
  }
  .ge {
    background-position: -176px 0;
  }
  .gd {
    background-position: -176px -11px;
  }
  .gb,
	.en {
    background-position: -176px -22px;
  }
  .ga {
    background-position: -176px -33px;
  }
  .fr, .gf, .re, .mf, .bl {
    background-position: -176px -44px;
  }
  .fo {
    background-position: -176px -55px;
  }
  .fm {
    background-position: -176px -66px;
  }
  .fk {
    background-position: -176px -77px;
  }
  .fj {
    background-position: -176px -88px;
  }
  .fi {
    background-position: -176px -99px;
  }
  .eu {
    background-position: -176px -121px;
  }
  .et {
    background-position: -176px -132px;
  }
  .es {
    background-position: -176px -143px;
  }
  .er {
    background-position: -176px -154px;
  }
  .eh {
    background-position: -16px -166px;
  }
  .eg {
    background-position: -32px -166px;
  }
  .ee {
    background-position: -48px -166px;
  }
  .ec {
    background-position: -64px -166px;
  }
  .dz {
    background-position: -80px -166px;
  }
  .do {
    background-position: -96px -166px;
  }
  .dm {
    background-position: -112px -166px;
  }
  .dk {
    background-position: -128px -166px;
  }
  .dj {
    background-position: -144px -166px;
  }
  .de {
    background-position: -160px -166px;
  }
  .cz {
    background-position: -176px -166px;
  }
  .cy {
    background-position: 0 -177px;
  }
  .cx {
    background-position: -16px -177px;
  }
  .cw {
    background-position: -32px -177px;
  }
  .cv {
    background-position: -48px -177px;
  }
  .cu {
    background-position: -64px -177px;
  }
  .cs {
    background-position: -80px -177px;
  }
  .cr {
    background-position: -96px -177px;
  }
  .co {
    background-position: -112px -177px;
  }
  .cn {
    background-position: -128px -177px;
  }
  .cm {
    background-position: -144px -177px;
  }
  .cl {
    background-position: -160px -177px;
  }
  .ck {
    background-position: -176px -177px;
  }
  .ci {
    background-position: -192px 0;
  }
  .cg {
    background-position: -192px -11px;
  }
  .cf {
    background-position: -192px -22px;
  }
  .cd {
    background-position: -192px -33px;
  }
  .cc {
    background-position: -192px -44px;
  }
  .ca {
    background-position: -192px -66px;
  }
  .bz {
    background-position: -192px -77px;
  }
  .by {
    background-position: -192px -88px;
  }
  .bw {
    background-position: -192px -99px;
  }
  .bt {
    background-position: -192px -110px;
  }
  .bs {
    background-position: -192px -121px;
  }
  .br {
    background-position: -192px -132px;
  }
  .bq {
    background-position: -192px -143px;
  }
  .bo {
    background-position: -192px -154px;
  }
  .bn {
    background-position: -192px -165px;
  }
  .bm {
    background-position: -192px -176px;
  }
  .bj {
    background-position: 0 -188px;
  }
  .bi {
    background-position: -16px -188px;
  }
  .bh {
    background-position: -32px -188px;
  }
  .bg {
    background-position: -48px -188px;
  }
  .bf {
    background-position: -64px -188px;
  }
  .be {
    background-position: -80px -188px;
  }
  .bd {
    background-position: -96px -188px;
  }
  .bb {
    background-position: -112px -188px;
  }
  .ba {
    background-position: -128px -188px;
  }
  .az {
    background-position: -144px -188px;
  }
  .ax {
    background-position: -160px -188px;
  }
  .aw {
    background-position: -176px -188px;
  }
  .au, .hm {
    background-position: -192px -188px;
  }
  .at {
    background-position: -208px 0;
  }
  .as {
    background-position: -208px -11px;
  }
  .ar {
    background-position: -208px -22px;
  }
  .ao {
    background-position: -208px -33px;
  }
  .an {
    background-position: -208px -44px;
  }
  .am {
    background-position: -208px -55px;
  }
  .al {
    background-position: -208px -66px;
  }
  .ai {
    background-position: -208px -77px;
  }
  .ag {
    background-position: -208px -88px;
  }
  .af {
    background-position: -208px -99px;
  }
  .ae {
    background-position: -208px -110px;
  }
  .ad {
    background-position: -208px -121px;
  }
  .np {
    background-position: -208px -132px;
  }
  .ch {
    background-position: -208px -143px;
  }
}

.country-select {
	//position:relative;
	//display:inline-block;

	* {
		box-sizing: border-box;
	}

	.hide {
		display: none;
	}

	.v-hide {
		visibility: hidden;
	}

	input {
		position: relative;
		z-index: 0;
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		padding-left: 44px;
	}

	.flag-dropdown {
		position: absolute;
		top: 0;
		bottom: 0;

		&:hover {
			cursor: pointer;

			.selected-flag {
				background-color: $grey-light;
			}
		}
	}

	input[disabled] + .flag-dropdown:hover {
		cursor: default;

		.selected-flag {
			background-color: transparent;
		}
	}

	.selected-flag {
		z-index: 1;
		position: relative;
		width: 38px;
		height: 100%;
		padding: 0 0 0 8px;

		.flag {
			position: absolute;
			top: 50%;
			margin-top: -5px;
		}

		.arrow {
			position: relative;
			top: 50%;
			margin-top: -2px;
			left: 20px;
			width: 0;
			height: 0;
			border-left: 3px solid transparent;
			border-right: 3px solid transparent;
			border-top: 4px solid #555;

			&.up {
				border-top: none;
				border-bottom: 4px solid #555;
			}
		}
	}

	.country-list {
		z-index: 2;
		position: absolute;
		width: 430px;
		max-height: 200px;
		margin: -2px 0 0 -1px;
		border: 1px solid $black;
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px;
		padding: 0;
		background-color: white;
		list-style: none;
		box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
		overflow-y: scroll;

		.flag {
			display: inline-block;
		}

		.divider {
			margin-bottom: 5px;
			border-bottom: 1px solid #ccc;
			padding-bottom: 5px;
		}

		.country {
			padding: 7px 10px;
			font-family: $font-grotesk;
			line-height: 11px;

			.dial-code {
				color: #999;
			}

			&.highlight {
				background-color: #000;
				background-color: rgba(0, 0, 0, 0.05);
			}
		}

		.flag,
		.country-name {
			margin-right: 6px;
			padding-left: 0.75em;
		}
	}

	&.inside {

		.flag-dropdown {
			padding: 1px;
		}

		input {

			&[type="text"],
			&[type="tel"] {
				padding-left: 44px;
				margin-left: 0;
			}
		}
	}

	&.outside input {

		&[type="text"],
		&[type="tel"] {
			border: none;
			margin-left: 38px;
		}
	}
}
