/*==========================================================================*\
  Page - Home
\*==========================================================================*/

// Settings
@import '../settings/variables';

// Tools
@import '../tools/functions';

// Vendors
@import '../vendors/modaal';
@import '../vendors/countryselect';

/* Intro
\*==========================================================================*/

.intro {
  max-width: 53.5em;
  margin-top: 2.5em;
  margin-right: auto;
  margin-bottom: 2.5em;
  margin-left: auto;
  color: #777777;

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  @media #{md('smaller')} {
    margin-top: 4em;
    margin-bottom: 4em;
    text-align: center;
  }

  @media #{md('small')} {
    margin-top: 8.125em;
    margin-bottom: 8.125em;
  }
}

/* Sign Up an Sign In
\*==========================================================================*/

.section__sign {
  margin-bottom: 2em;

  @media #{md('small')} {
    margin-bottom: 8.125em;
  }

  @media #{md('large')} {
    margin-bottom: 12em;
  }
}

.sign {
  &:not(:last-child) {
    margin-bottom: 3em;
  }

  .card {
    border-radius: 5px;
  }

  @media #{md('small')} {
    margin-bottom: 0 !important;
  }
}

.sign__title {
  margin-top: 0;
  margin-bottom: 0;
  font-family: $font-paradox;

  @media #{md('large')} {
    font-size: 2.875em;
  }
}

.sign__description {
  margin-top: 1em;
  margin-bottom: 0;
  line-height: 2em;
  color: #777777;

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.sign__form {
  margin-top: 2em;
}

.field-label {
  .field-label__text {
    display: block;
  }

  .tooltip__button {
    color: #777777;
  }

  .tooltip-button__text {
    font-size: 1em;
  }

  @media #{md('large')} {
    .tooltip {
      display: none;
    }
  }
}

.sign__submit {
  width: 100%;
  margin-top: 2em;

  @media #{md('smaller')} {
    margin-top: 4em;
  }
}

/* Sign Up
\*==========================================================================*/

.sign--signup {
  .signup__signup-focus--desktop {
    display: none;
  }

  @media #{md('large')} {
    .sign__description {
      max-width: 75%;
    }

    .card {
      padding: 3em;
      box-shadow: 0 0 30px -9px rgba(0, 0, 0, 0.2);
    }

    .sign__form {
      max-width: 60%;
    }

    .signup__signup-focus {
      position: absolute;
      top: -2.45em;
      left: calc(100% + 3.125em);
    }

    .signup__signup-focus--desktop {
      display: block;
    }
  }

  @media #{md('larger')} {
    .sign__form {
      max-width: 71%;
    }

    .signup__signup-focus {
      left: calc(100% + 2em);
    }
  }

  @media #{md('largest')} {
    .sign__description {
      max-width: 90%;
    }

    .card {
      padding: 5.5em;
    }

    .sign__form {
      max-width: 70%;
    }

    .signup__signup-focus {
      left: calc(100% + 3.125em);
    }
  }
}

/* Sign In
\*==========================================================================*/

.sign--signin {
  @media #{md('small')} {
    .sign__form {
      margin-top: 3.3em;
    }
  }

  @media #{md('medium')} {
    .sign__form {
      margin-top: 5.3em;
    }
  }

  @media #{md('large')} {
    .card {
      margin-top: 2em;
    }

    .sign__form {
      margin-top: 5.3em;
    }
  }

  @media #{md('larger')} {
    .sign__form {
      max-width: 71%;
      margin-top: 2em;
    }
  }

  @media #{md('largest')} {
    .sign__description {
      max-width: 70%;
    }

    .card {
      margin-top: 5.5em;
    }

    .sign__form {
      max-width: 65%;
    }
  }
}

/* Map
\*==========================================================================*/

.section_map {
  max-width: 68em;
  margin-right: auto;
  margin-bottom: 1em;
  margin-left: auto;

  .google-maps {
    &.is-active {
      height: 500px;

      + a {
        display: none;
      }
    }
  }

  img {
    width: 100%;
  }

  @media #{md('small')} {
    margin-bottom: 6em;
  }

  @media #{md('medium')} {
    margin-bottom: 9em;
  }
}

/* Informations
\*==========================================================================*/

.section_infos {
  max-width: 68em;
  margin-right: auto;
  margin-bottom: 1.5em;
  margin-left: auto;

  .col-info {
    &:not(:first-child) {
      margin-top: 1em;
    }
  }

  @media #{md('smaller')} {
    .col-info {
      margin-top: 1em;

      &:nth-child(1),
      &:nth-child(2) {
        margin-top: 0;
      }
    }
  }

  @media #{md('small')} {
    .col-info {
      margin-top: 1em;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        margin-top: 0;
      }
    }
  }
}

.infos__row {
  display: flex;
  flex-wrap: wrap;
  justify-items: stretch;
}

.info {
  font-family: $font-grotesk;
  text-align: center;
  background: $grey-light;

  a {
    display: inline-block;
    margin-top: 0.5em;
    text-decoration: underline;

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  .info-inner {
    padding: 2em;
    background: $grey-light;
  }

  .info-title {
    margin-top: 0;
    font-family: $font-family;
    font-size: 12px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: $letter-spacing-200;
  }

  .info-description {
    margin-bottom: 0;
    line-height: 2em;
    color: #777777;
  }

  @media #{md('smaller')} {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: baseline;
    height: 100%;

    .info-title {
      margin-bottom: 2em;
    }
  }

  @media #{md('small')} {
    .info-inner {
      padding: 1em;
    }
  }

  @media #{md('medium')} {
    justify-content: center;
  }

  @media #{md('large')} {
    .info-inner {
      padding: 2.5em 1em;
    }
  }
}

/* Aside
\*==========================================================================*/

.form_signup {
  .main-aside & {
    padding: 1.5em;
  }

  .aside-title {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1.882352941em;
    font-weight: 400;
  }

  .aside-description {
    margin-bottom: 2em;
    color: #777777;

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-field__checkbox {
    margin-bottom: 1em;
  }

  .sign__submit {
    width: 100%;
    margin-top: 2em;
  }

  @media #{md('smaller')} {
    .main-aside & {
      padding: 3em;
    }
  }

  @media #{md('large')} {
    .main-aside & {
      padding: 4em;
    }
  }
}
