@charset "UTF-8";
/*==========================================================================*\
  Page - Home
\*==========================================================================*/
/*---------------------------------------*\
		Styles et réglages globaux
\*---------------------------------------*/
/*------------------------------------*\
		Easing
\*------------------------------------*/
/*------------------------------------*\
		Functions
\*------------------------------------*/
/**
 * A function helper to avoid having to type `map-get($z-layers, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 * @param  {string} $layer The name of the z-index
 * @param  {number} $var   The modifier if needed
 * @return {number}        The corresponding z-index based on the $z-layers var
 */
/**
 * Remove units from the given number
 * @param  {number} $number The number with units
 * @return {number}
 */
/**
 * Create variables for the media queries
 * @param  {string} $breakpoint The breakpoint
 * @param  {string} $type       Type of media query (min or max)
 * @param  {string} $unit       The unit for the media queries (em or px)
 * @return {string}             A media query expression
 */
/**
 * Slightly lighten a color
 * @access public
 * @param {Color} $color - color to tint
 * @param {Number} $percentage - percentage of `$color` in returned color
 * @return {Color}
 */
/**
 * Slightly darken a color
 * @access public
 * @param  {color}  $color       color to shade
 * @param  {number} $percentage  percentage of `$color` in returned color
 * @return {Color}
 */
/**
 * Power function
 * @param  {number} $number Number to apply power
 * @param  {number} $exp    The exponant for the power
 * @return {number}         The powered number
 */
/**
 * Factorial function
 * @param  {number} $number The number to factorize
 * @return {number}         The factorised number
 */
/**
 * Pi reference
 * @return {number} Retrun PI with 11 decimals
 */
/**
 * Convert deg to rad
 * @param  {string} $angle The angle to convert
 * @return {number}        The unitless angle converted to rad
 */
/**
 * Calculate the sinus of an angle
 * @param  {string} $angle The angle to compute
 * @return {number}        The sinus of the given angle
 */
/**
 * Calculate the cosinus of an angle
 * @param  {string} $angle The angle to compute
 * @return {number}        The cosinus of the given angle
 */
/**
 * Calculate the tangent of an angle
 * @param  {string} $angle The angle to compute
 * @return {number}        The tangent of the given angle
 */
/*!
	Modaal - accessible modals - v0.2.9
	by Humaan, for all humans.
	http://humaan.com
 */
/*---------------------------------------*\
		Styles et réglages globaux
\*---------------------------------------*/
/*------------------------------------*\
		Easing
\*------------------------------------*/
.modaal-accessible-hide {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.modaal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  opacity: 0;
}

.modaal-wrapper {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: auto;
  opacity: 1;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}
.modaal-wrapper * {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
}
.modaal-wrapper .modaal-close {
  border: none;
  background: transparent;
  padding: 0;
  -webkit-appearance: none;
}
.modaal-wrapper.modaal-start_none {
  display: none;
  opacity: 1;
}
.modaal-wrapper.modaal-start_fade {
  opacity: 0;
}
.modaal-wrapper *[tabindex="0"] {
  outline: none !important;
}
.modaal-wrapper.modaal-fullscreen {
  overflow: hidden;
}

.modaal-outer-wrapper {
  display: table;
  position: relative;
  width: 100%;
  height: 100%;
}
.modaal-fullscreen .modaal-outer-wrapper {
  display: block;
}

.modaal-inner-wrapper {
  display: table-cell;
  width: 100%;
  height: 100%;
  position: relative;
  vertical-align: middle;
  text-align: center;
  padding: 80px 25px;
}
.modaal-fullscreen .modaal-inner-wrapper {
  padding: 0;
  display: block;
  vertical-align: top;
}

.modaal-container {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: auto;
  text-align: left;
  color: #000;
  max-width: 780px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  cursor: auto;
}
.modaal-container.is_loading {
  height: 100px;
  width: 100px;
  overflow: hidden;
}
.modaal-fullscreen .modaal-container {
  max-width: none;
  height: 100%;
  overflow: auto;
}

.modaal-close {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #fff;
  cursor: pointer;
  opacity: 1;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0);
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
}
.modaal-close:focus, .modaal-close:hover {
  outline: none;
  background: transparent;
}
.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #000;
}
.modaal-close span {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}
.modaal-close:before, .modaal-close:after {
  display: block;
  content: " ";
  position: absolute;
  top: 14px;
  left: 23px;
  width: 2px;
  height: 22px;
  background: #000;
  transition: background 0.2s ease-in-out;
}
.modaal-close:before {
  transform: rotate(-45deg);
}
.modaal-close:after {
  transform: rotate(45deg);
}
.modaal-fullscreen .modaal-close {
  background: #afb7bc;
  right: 10px;
  top: 10px;
}

.modaal-content {
  border-radius: 5px;
}

.modaal-content-container {
  border: 1px solid #000;
  padding: 35px;
  text-align: center;
  font-family: "urwgrotesk-lig", "Helvetica Neue", Arial, sans-serif;
  border-radius: 5px;
}
.modaal-content-container .modaal-title {
  margin-top: 0;
  margin-bottom: 1.5em;
  font-size: 30px;
  font-weight: 400;
}
.modaal-content-container .modaal-card {
  display: block;
  margin-bottom: 1.5em;
  text-align: center;
  color: #000;
}

.modal-share {
  margin-top: 1.5em;
}
.modal-share .share-title {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-family: "paradox-st", Georgia, serif;
  font-size: 1.375em;
}
.modal-share .share-item {
  padding: 0.5em;
  line-height: 0;
}
.modal-share .share-item.share-twitter svg {
  width: 18px;
  height: 18px;
}
.modal-share .share-item svg {
  width: 20px;
  height: 20px;
}

.modaal-confirm-wrap {
  padding: 30px 0 0;
  text-align: center;
  font-size: 0;
}

.modaal-confirm-btn {
  font-size: 14px;
  display: inline-block;
  margin: 0 10px;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  background: transparent;
}
.modaal-confirm-btn.modaal-ok {
  padding: 10px 15px;
  color: #fff;
  background: #555;
  border-radius: 3px;
  transition: background 0.2s ease-in-out;
}
.modaal-confirm-btn.modaal-ok:hover {
  background: #2f2f2f;
}
.modaal-confirm-btn.modaal-cancel {
  text-decoration: underline;
}
.modaal-confirm-btn.modaal-cancel:hover {
  text-decoration: none;
  color: #2f2f2f;
}

@keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.modaal-instagram .modaal-container {
  width: auto;
  background: transparent;
  box-shadow: none !important;
}
.modaal-instagram .modaal-content-container {
  padding: 0;
  background: transparent;
}
.modaal-instagram .modaal-content-container > blockquote {
  width: 1px !important;
  height: 1px !important;
  opacity: 0 !important;
}
.modaal-instagram iframe {
  opacity: 0;
  margin: -6px !important;
  border-radius: 0 !important;
  width: 1000px !important;
  max-width: 800px !important;
  box-shadow: none !important;
  animation: instaReveal 1s linear forwards;
}

.modaal-image .modaal-container {
  width: auto;
  max-width: 1500px;
}

.modaal-gallery-wrap {
  position: relative;
  color: #fff;
}

.modaal-gallery-item {
  display: none;
}
.modaal-gallery-item img {
  display: block;
}
.modaal-gallery-item.is_active {
  display: block;
}

.modaal-gallery-label {
  position: absolute;
  left: 0;
  width: 100%;
  margin: 20px 0 0;
  font-size: 18px;
  text-align: center;
  color: #fff;
}
.modaal-gallery-label:focus {
  outline: none;
}

.modaal-gallery-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
  cursor: pointer;
  color: #fff;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
}
.modaal-gallery-control.is_hidden {
  opacity: 0;
  cursor: default;
}
.modaal-gallery-control:focus, .modaal-gallery-control:hover {
  outline: none;
  background: #fff;
}
.modaal-gallery-control:focus:before, .modaal-gallery-control:focus:after, .modaal-gallery-control:hover:before, .modaal-gallery-control:hover:after {
  background: #afb7bc;
}
.modaal-gallery-control span {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}
.modaal-gallery-control:before, .modaal-gallery-control:after {
  display: block;
  content: " ";
  position: absolute;
  top: 16px;
  left: 25px;
  width: 4px;
  height: 18px;
  border-radius: 4px;
  background: #fff;
  transition: background 0.2s ease-in-out;
}
.modaal-gallery-control:before {
  margin: -5px 0 0;
  transform: rotate(-45deg);
}
.modaal-gallery-control:after {
  margin: 5px 0 0;
  transform: rotate(45deg);
}

.modaal-gallery-next {
  left: 100%;
  margin-left: 40px;
}

.modaal-gallery-prev {
  right: 100%;
  margin-right: 40px;
}
.modaal-gallery-prev:before, .modaal-gallery-prev:after {
  left: 22px;
}
.modaal-gallery-prev:before {
  margin: 5px 0 0;
  transform: rotate(-45deg);
}
.modaal-gallery-prev:after {
  margin: -5px 0 0;
  transform: rotate(45deg);
}

.modaal-video-wrap {
  margin: auto 50px;
  position: relative;
}

.modaal-video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background: #000;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}
.modaal-video-container iframe,
.modaal-video-container object,
.modaal-video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modaal-iframe .modaal-container {
  width: auto;
  max-width: none;
}

.modaal-iframe-elem {
  width: 100%;
  display: block;
}

@media only screen and (min-width: 1400px) {
  .modaal-video-container {
    padding-bottom: 0;
    height: 731px;
  }
}
@media only screen and (max-width: 1140px) {
  .modaal-gallery-item img {
    width: 100%;
  }

  .modaal-gallery-control {
    top: auto;
    bottom: 20px;
    transform: none;
    background: rgba(0, 0, 0, 0.7);
  }
  .modaal-gallery-control:before, .modaal-gallery-control:after {
    background: #fff;
  }

  .modaal-gallery-next {
    left: auto;
    right: 20px;
  }

  .modaal-gallery-prev {
    left: 20px;
    right: auto;
  }
}
@media screen and (max-width: 900px) {
  .modaal-instagram iframe {
    width: 500px !important;
  }
}
@media screen and (max-height: 1100px) {
  .modaal-instagram iframe {
    width: 700px !important;
  }
}
@media screen and (max-height: 1000px) {
  .modaal-inner-wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .modaal-instagram iframe {
    width: 600px !important;
  }
}
@media screen and (max-height: 900px) {
  .modaal-instagram iframe {
    width: 500px !important;
  }

  .modaal-video-container {
    max-width: 900px;
    max-height: 510px;
  }
}
@media only screen and (max-width: 600px) {
  .modaal-instagram iframe {
    width: 280px !important;
  }
}
@media only screen and (max-height: 1024px) {
  .modaal-gallery-item img {
    width: auto !important;
    max-height: 85vh;
  }
}
@media only screen and (max-height: 820px) {
  .modaal-gallery-label {
    display: none;
  }
}
.modaal-loading-spinner {
  background: none;
  position: absolute;
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -100px;
  transform: scale(0.25);
}

@-ms-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.modaal-loading-spinner > div {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  margin-top: 4px;
  position: absolute;
}

.modaal-loading-spinner > div > div {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: #fff;
}

.modaal-loading-spinner > div:nth-of-type(1) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -webkit-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}

.modaal-loading-spinner > div:nth-of-type(2) > div, .modaal-loading-spinner > div:nth-of-type(3) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
}

.modaal-loading-spinner > div:nth-of-type(1) {
  -ms-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(2) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.12s;
  -moz-animation-delay: 0.12s;
  -webkit-animation-delay: 0.12s;
  -o-animation-delay: 0.12s;
  animation-delay: 0.12s;
}

.modaal-loading-spinner > div:nth-of-type(2) {
  -ms-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(3) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.25s;
  -moz-animation-delay: 0.25s;
  -webkit-animation-delay: 0.25s;
  -o-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

.modaal-loading-spinner > div:nth-of-type(4) > div, .modaal-loading-spinner > div:nth-of-type(5) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
}

.modaal-loading-spinner > div:nth-of-type(3) {
  -ms-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(4) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.37s;
  -moz-animation-delay: 0.37s;
  -webkit-animation-delay: 0.37s;
  -o-animation-delay: 0.37s;
  animation-delay: 0.37s;
}

.modaal-loading-spinner > div:nth-of-type(4) {
  -ms-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(5) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.modaal-loading-spinner > div:nth-of-type(6) > div, .modaal-loading-spinner > div:nth-of-type(7) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
}

.modaal-loading-spinner > div:nth-of-type(5) {
  -ms-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(6) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.62s;
  -moz-animation-delay: 0.62s;
  -webkit-animation-delay: 0.62s;
  -o-animation-delay: 0.62s;
  animation-delay: 0.62s;
}

.modaal-loading-spinner > div:nth-of-type(6) {
  -ms-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(7) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.75s;
  -moz-animation-delay: 0.75s;
  -webkit-animation-delay: 0.75s;
  -o-animation-delay: 0.75s;
  animation-delay: 0.75s;
}

.modaal-loading-spinner > div:nth-of-type(7) {
  -ms-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(8) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.87s;
  -moz-animation-delay: 0.87s;
  -webkit-animation-delay: 0.87s;
  -o-animation-delay: 0.87s;
  animation-delay: 0.87s;
}

.modaal-loading-spinner > div:nth-of-type(8) {
  -ms-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
}

/*---------------------------------------*\
		Styles et réglages globaux
\*---------------------------------------*/
/*------------------------------------*\
		Easing
\*------------------------------------*/
.country-select .flag {
  width: 16px;
  height: 11px;
  background: url("/assets/img/flags.png");
}

.country-select .zw {
  background-position: 0 0;
}
.country-select .zm {
  background-position: -16px 0;
}
.country-select .za {
  background-position: 0 -11px;
}
.country-select .yt {
  background-position: -16px -11px;
}
.country-select .ye {
  background-position: -32px 0;
}
.country-select .ws {
  background-position: -32px -11px;
}
.country-select .wf {
  background-position: 0 -22px;
}
.country-select .vu {
  background-position: -32px -22px;
}
.country-select .vn {
  background-position: 0 -33px;
}
.country-select .vi {
  background-position: -16px -33px;
}
.country-select .vg {
  background-position: -32px -33px;
}
.country-select .ve {
  background-position: -48px 0;
}
.country-select .vc {
  background-position: -48px -11px;
}
.country-select .va {
  background-position: -48px -22px;
}
.country-select .uz {
  background-position: -48px -33px;
}
.country-select .uy {
  background-position: 0 -44px;
}
.country-select .us, .country-select .um {
  background-position: -16px -44px;
}
.country-select .ug {
  background-position: -32px -44px;
}
.country-select .ua {
  background-position: -48px -44px;
}
.country-select .tz {
  background-position: -64px 0;
}
.country-select .tw {
  background-position: -64px -11px;
}
.country-select .tv {
  background-position: -64px -22px;
}
.country-select .tt {
  background-position: -64px -33px;
}
.country-select .tr {
  background-position: -64px -44px;
}
.country-select .to {
  background-position: 0 -55px;
}
.country-select .tn {
  background-position: -16px -55px;
}
.country-select .tm {
  background-position: -32px -55px;
}
.country-select .tl {
  background-position: -48px -55px;
}
.country-select .tk {
  background-position: -64px -55px;
}
.country-select .tj {
  background-position: 0 -66px;
}
.country-select .th {
  background-position: -16px -66px;
}
.country-select .tg {
  background-position: -32px -66px;
}
.country-select .tf {
  background-position: -48px -66px;
}
.country-select .td {
  background-position: -64px -66px;
}
.country-select .tc {
  background-position: -80px 0;
}
.country-select .sz {
  background-position: -80px -11px;
}
.country-select .sy {
  background-position: -80px -22px;
}
.country-select .sx {
  background-position: -80px -33px;
}
.country-select .sv {
  background-position: -80px -44px;
}
.country-select .st {
  background-position: -80px -55px;
}
.country-select .ss {
  background-position: -80px -66px;
}
.country-select .sr {
  background-position: 0 -77px;
}
.country-select .so {
  background-position: -16px -77px;
}
.country-select .sn {
  background-position: -32px -77px;
}
.country-select .sm {
  background-position: -48px -77px;
}
.country-select .sl {
  background-position: -64px -77px;
}
.country-select .sk {
  background-position: -80px -77px;
}
.country-select .si {
  background-position: -96px 0;
}
.country-select .sh {
  background-position: -96px -11px;
}
.country-select .sg {
  background-position: -96px -22px;
}
.country-select .se {
  background-position: -96px -33px;
}
.country-select .sd {
  background-position: -96px -44px;
}
.country-select .sc {
  background-position: -96px -66px;
}
.country-select .sb {
  background-position: -96px -77px;
}
.country-select .sa {
  background-position: 0 -88px;
}
.country-select .rw {
  background-position: -16px -88px;
}
.country-select .ru {
  background-position: -32px -88px;
}
.country-select .rs {
  background-position: -48px -88px;
}
.country-select .ro {
  background-position: -64px -88px;
}
.country-select .qa {
  background-position: -80px -88px;
}
.country-select .py {
  background-position: -96px -88px;
}
.country-select .pw {
  background-position: 0 -99px;
}
.country-select .pt {
  background-position: -16px -99px;
}
.country-select .ps {
  background-position: -32px -99px;
}
.country-select .pr {
  background-position: -48px -99px;
}
.country-select .pn {
  background-position: -64px -99px;
}
.country-select .pm {
  background-position: -80px -99px;
}
.country-select .pl {
  background-position: -96px -99px;
}
.country-select .pk {
  background-position: -112px 0;
}
.country-select .ph {
  background-position: -112px -11px;
}
.country-select .pg {
  background-position: -112px -22px;
}
.country-select .pf {
  background-position: -112px -33px;
}
.country-select .pe {
  background-position: -112px -44px;
}
.country-select .pa {
  background-position: -112px -55px;
}
.country-select .om {
  background-position: -112px -66px;
}
.country-select .nz {
  background-position: -112px -77px;
}
.country-select .nu {
  background-position: -112px -88px;
}
.country-select .nr {
  background-position: -112px -99px;
}
.country-select .no, .country-select .bv, .country-select .sj {
  background-position: 0 -110px;
}
.country-select .nl {
  background-position: -16px -110px;
}
.country-select .ni {
  background-position: -32px -110px;
}
.country-select .ng {
  background-position: -48px -110px;
}
.country-select .nf {
  background-position: -64px -110px;
}
.country-select .ne {
  background-position: -80px -110px;
}
.country-select .nc {
  background-position: -96px -110px;
}
.country-select .na {
  background-position: -112px -110px;
}
.country-select .mz {
  background-position: -128px 0;
}
.country-select .my {
  background-position: -128px -11px;
}
.country-select .mx {
  background-position: -128px -22px;
}
.country-select .mw {
  background-position: -128px -33px;
}
.country-select .mv {
  background-position: -128px -44px;
}
.country-select .mu {
  background-position: -128px -55px;
}
.country-select .mt {
  background-position: -128px -66px;
}
.country-select .ms {
  background-position: -128px -77px;
}
.country-select .mr {
  background-position: -128px -88px;
}
.country-select .mq {
  background-position: -128px -99px;
}
.country-select .mp {
  background-position: -128px -110px;
}
.country-select .mo {
  background-position: 0 -121px;
}
.country-select .mn {
  background-position: -16px -121px;
}
.country-select .mm {
  background-position: -32px -121px;
}
.country-select .ml {
  background-position: -48px -121px;
}
.country-select .mk {
  background-position: -64px -121px;
}
.country-select .mh {
  background-position: -80px -121px;
}
.country-select .mg {
  background-position: -96px -121px;
}
.country-select .me {
  background-position: 0 -132px;
  height: 12px;
}
.country-select .md {
  background-position: -112px -121px;
}
.country-select .mc {
  background-position: -128px -121px;
}
.country-select .ma {
  background-position: -16px -132px;
}
.country-select .ly {
  background-position: -32px -132px;
}
.country-select .lv {
  background-position: -48px -132px;
}
.country-select .lu {
  background-position: -64px -132px;
}
.country-select .lt {
  background-position: -80px -132px;
}
.country-select .ls {
  background-position: -96px -132px;
}
.country-select .lr {
  background-position: -112px -132px;
}
.country-select .lk {
  background-position: -128px -132px;
}
.country-select .li {
  background-position: -144px 0;
}
.country-select .lc {
  background-position: -144px -11px;
}
.country-select .lb {
  background-position: -144px -22px;
}
.country-select .la {
  background-position: -144px -33px;
}
.country-select .kz {
  background-position: -144px -44px;
}
.country-select .ky {
  background-position: -144px -55px;
}
.country-select .kw {
  background-position: -144px -66px;
}
.country-select .kr {
  background-position: -144px -77px;
}
.country-select .kp {
  background-position: -144px -88px;
}
.country-select .kn {
  background-position: -144px -99px;
}
.country-select .km {
  background-position: -144px -110px;
}
.country-select .ki {
  background-position: -144px -121px;
}
.country-select .kh {
  background-position: -144px -132px;
}
.country-select .kg {
  background-position: 0 -144px;
}
.country-select .ke {
  background-position: -16px -144px;
}
.country-select .jp {
  background-position: -32px -144px;
}
.country-select .jo {
  background-position: -48px -144px;
}
.country-select .jm {
  background-position: -64px -144px;
}
.country-select .je {
  background-position: -80px -144px;
}
.country-select .it {
  background-position: -96px -144px;
}
.country-select .is {
  background-position: -112px -144px;
}
.country-select .ir {
  background-position: -128px -144px;
}
.country-select .iq {
  background-position: -144px -144px;
}
.country-select .io {
  background-position: -160px 0;
}
.country-select .in {
  background-position: -160px -11px;
}
.country-select .im {
  background-position: -160px -22px;
  height: 9px;
}
.country-select .il {
  background-position: -160px -31px;
}
.country-select .ie {
  background-position: -160px -42px;
}
.country-select .id {
  background-position: -160px -53px;
}
.country-select .hu {
  background-position: -160px -64px;
}
.country-select .ht {
  background-position: -160px -75px;
}
.country-select .hr {
  background-position: -160px -86px;
}
.country-select .hn {
  background-position: -160px -97px;
}
.country-select .hk {
  background-position: -160px -108px;
}
.country-select .gy {
  background-position: -160px -119px;
}
.country-select .gw {
  background-position: -160px -130px;
}
.country-select .gu {
  background-position: -160px -141px;
}
.country-select .gt {
  background-position: 0 -155px;
}
.country-select .gs {
  background-position: -16px -155px;
}
.country-select .gr {
  background-position: -32px -155px;
}
.country-select .gq {
  background-position: -48px -155px;
}
.country-select .gp {
  background-position: -64px -155px;
}
.country-select .gn {
  background-position: -80px -155px;
}
.country-select .gm {
  background-position: -96px -155px;
}
.country-select .gl {
  background-position: -112px -155px;
}
.country-select .gi {
  background-position: -128px -155px;
}
.country-select .gh {
  background-position: -144px -155px;
}
.country-select .gg {
  background-position: -160px -155px;
}
.country-select .ge {
  background-position: -176px 0;
}
.country-select .gd {
  background-position: -176px -11px;
}
.country-select .gb,
.country-select .en {
  background-position: -176px -22px;
}
.country-select .ga {
  background-position: -176px -33px;
}
.country-select .fr, .country-select .gf, .country-select .re, .country-select .mf, .country-select .bl {
  background-position: -176px -44px;
}
.country-select .fo {
  background-position: -176px -55px;
}
.country-select .fm {
  background-position: -176px -66px;
}
.country-select .fk {
  background-position: -176px -77px;
}
.country-select .fj {
  background-position: -176px -88px;
}
.country-select .fi {
  background-position: -176px -99px;
}
.country-select .eu {
  background-position: -176px -121px;
}
.country-select .et {
  background-position: -176px -132px;
}
.country-select .es {
  background-position: -176px -143px;
}
.country-select .er {
  background-position: -176px -154px;
}
.country-select .eh {
  background-position: -16px -166px;
}
.country-select .eg {
  background-position: -32px -166px;
}
.country-select .ee {
  background-position: -48px -166px;
}
.country-select .ec {
  background-position: -64px -166px;
}
.country-select .dz {
  background-position: -80px -166px;
}
.country-select .do {
  background-position: -96px -166px;
}
.country-select .dm {
  background-position: -112px -166px;
}
.country-select .dk {
  background-position: -128px -166px;
}
.country-select .dj {
  background-position: -144px -166px;
}
.country-select .de {
  background-position: -160px -166px;
}
.country-select .cz {
  background-position: -176px -166px;
}
.country-select .cy {
  background-position: 0 -177px;
}
.country-select .cx {
  background-position: -16px -177px;
}
.country-select .cw {
  background-position: -32px -177px;
}
.country-select .cv {
  background-position: -48px -177px;
}
.country-select .cu {
  background-position: -64px -177px;
}
.country-select .cs {
  background-position: -80px -177px;
}
.country-select .cr {
  background-position: -96px -177px;
}
.country-select .co {
  background-position: -112px -177px;
}
.country-select .cn {
  background-position: -128px -177px;
}
.country-select .cm {
  background-position: -144px -177px;
}
.country-select .cl {
  background-position: -160px -177px;
}
.country-select .ck {
  background-position: -176px -177px;
}
.country-select .ci {
  background-position: -192px 0;
}
.country-select .cg {
  background-position: -192px -11px;
}
.country-select .cf {
  background-position: -192px -22px;
}
.country-select .cd {
  background-position: -192px -33px;
}
.country-select .cc {
  background-position: -192px -44px;
}
.country-select .ca {
  background-position: -192px -66px;
}
.country-select .bz {
  background-position: -192px -77px;
}
.country-select .by {
  background-position: -192px -88px;
}
.country-select .bw {
  background-position: -192px -99px;
}
.country-select .bt {
  background-position: -192px -110px;
}
.country-select .bs {
  background-position: -192px -121px;
}
.country-select .br {
  background-position: -192px -132px;
}
.country-select .bq {
  background-position: -192px -143px;
}
.country-select .bo {
  background-position: -192px -154px;
}
.country-select .bn {
  background-position: -192px -165px;
}
.country-select .bm {
  background-position: -192px -176px;
}
.country-select .bj {
  background-position: 0 -188px;
}
.country-select .bi {
  background-position: -16px -188px;
}
.country-select .bh {
  background-position: -32px -188px;
}
.country-select .bg {
  background-position: -48px -188px;
}
.country-select .bf {
  background-position: -64px -188px;
}
.country-select .be {
  background-position: -80px -188px;
}
.country-select .bd {
  background-position: -96px -188px;
}
.country-select .bb {
  background-position: -112px -188px;
}
.country-select .ba {
  background-position: -128px -188px;
}
.country-select .az {
  background-position: -144px -188px;
}
.country-select .ax {
  background-position: -160px -188px;
}
.country-select .aw {
  background-position: -176px -188px;
}
.country-select .au, .country-select .hm {
  background-position: -192px -188px;
}
.country-select .at {
  background-position: -208px 0;
}
.country-select .as {
  background-position: -208px -11px;
}
.country-select .ar {
  background-position: -208px -22px;
}
.country-select .ao {
  background-position: -208px -33px;
}
.country-select .an {
  background-position: -208px -44px;
}
.country-select .am {
  background-position: -208px -55px;
}
.country-select .al {
  background-position: -208px -66px;
}
.country-select .ai {
  background-position: -208px -77px;
}
.country-select .ag {
  background-position: -208px -88px;
}
.country-select .af {
  background-position: -208px -99px;
}
.country-select .ae {
  background-position: -208px -110px;
}
.country-select .ad {
  background-position: -208px -121px;
}
.country-select .np {
  background-position: -208px -132px;
}
.country-select .ch {
  background-position: -208px -143px;
}

.country-select * {
  box-sizing: border-box;
}
.country-select .hide {
  display: none;
}
.country-select .v-hide {
  visibility: hidden;
}
.country-select input {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 44px;
}
.country-select .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
}
.country-select .flag-dropdown:hover {
  cursor: pointer;
}
.country-select .flag-dropdown:hover .selected-flag {
  background-color: #f5f6f8;
}
.country-select input[disabled] + .flag-dropdown:hover {
  cursor: default;
}
.country-select input[disabled] + .flag-dropdown:hover .selected-flag {
  background-color: transparent;
}
.country-select .selected-flag {
  z-index: 1;
  position: relative;
  width: 38px;
  height: 100%;
  padding: 0 0 0 8px;
}
.country-select .selected-flag .flag {
  position: absolute;
  top: 50%;
  margin-top: -5px;
}
.country-select .selected-flag .arrow {
  position: relative;
  top: 50%;
  margin-top: -2px;
  left: 20px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}
.country-select .selected-flag .arrow.up {
  border-top: none;
  border-bottom: 4px solid #555;
}
.country-select .country-list {
  z-index: 2;
  position: absolute;
  width: 430px;
  max-height: 200px;
  margin: -2px 0 0 -1px;
  border: 1px solid #010101;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 0;
  background-color: white;
  list-style: none;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  overflow-y: scroll;
}
.country-select .country-list .flag {
  display: inline-block;
}
.country-select .country-list .divider {
  margin-bottom: 5px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}
.country-select .country-list .country {
  padding: 7px 10px;
  font-family: "urwgrotesk-lig", "Helvetica Neue", Arial, sans-serif;
  line-height: 11px;
}
.country-select .country-list .country .dial-code {
  color: #999;
}
.country-select .country-list .country.highlight {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
}
.country-select .country-list .flag,
.country-select .country-list .country-name {
  margin-right: 6px;
  padding-left: 0.75em;
}
.country-select.inside .flag-dropdown {
  padding: 1px;
}
.country-select.inside input[type=text], .country-select.inside input[type=tel] {
  padding-left: 44px;
  margin-left: 0;
}
.country-select.outside input[type=text], .country-select.outside input[type=tel] {
  border: none;
  margin-left: 38px;
}

/* Intro
\*==========================================================================*/
.intro {
  max-width: 53.5em;
  margin-top: 2.5em;
  margin-right: auto;
  margin-bottom: 2.5em;
  margin-left: auto;
  color: #777777;
}
.intro p:last-child {
  margin-bottom: 0;
}
@media (min-width: 30em) {
  .intro {
    margin-top: 4em;
    margin-bottom: 4em;
    text-align: center;
  }
}
@media (min-width: 48em) {
  .intro {
    margin-top: 8.125em;
    margin-bottom: 8.125em;
  }
}

/* Sign Up an Sign In
\*==========================================================================*/
.section__sign {
  margin-bottom: 2em;
}
@media (min-width: 48em) {
  .section__sign {
    margin-bottom: 8.125em;
  }
}
@media (min-width: 80em) {
  .section__sign {
    margin-bottom: 12em;
  }
}

.sign:not(:last-child) {
  margin-bottom: 3em;
}
.sign .card {
  border-radius: 5px;
}
@media (min-width: 48em) {
  .sign {
    margin-bottom: 0 !important;
  }
}

.sign__title {
  margin-top: 0;
  margin-bottom: 0;
  font-family: "paradox-st", Georgia, serif;
}
@media (min-width: 80em) {
  .sign__title {
    font-size: 2.875em;
  }
}

.sign__description {
  margin-top: 1em;
  margin-bottom: 0;
  line-height: 2em;
  color: #777777;
}
.sign__description p:last-child {
  margin-bottom: 0;
}

.sign__form {
  margin-top: 2em;
}

.field-label .field-label__text {
  display: block;
}
.field-label .tooltip__button {
  color: #777777;
}
.field-label .tooltip-button__text {
  font-size: 1em;
}
@media (min-width: 80em) {
  .field-label .tooltip {
    display: none;
  }
}

.sign__submit {
  width: 100%;
  margin-top: 2em;
}
@media (min-width: 30em) {
  .sign__submit {
    margin-top: 4em;
  }
}

/* Sign Up
\*==========================================================================*/
.sign--signup .signup__signup-focus--desktop {
  display: none;
}
@media (min-width: 80em) {
  .sign--signup .sign__description {
    max-width: 75%;
  }
  .sign--signup .card {
    padding: 3em;
    box-shadow: 0 0 30px -9px rgba(0, 0, 0, 0.2);
  }
  .sign--signup .sign__form {
    max-width: 60%;
  }
  .sign--signup .signup__signup-focus {
    position: absolute;
    top: -2.45em;
    left: calc(100% + 3.125em);
  }
  .sign--signup .signup__signup-focus--desktop {
    display: block;
  }
}
@media (min-width: 90em) {
  .sign--signup .sign__form {
    max-width: 71%;
  }
  .sign--signup .signup__signup-focus {
    left: calc(100% + 2em);
  }
}
@media (min-width: 120em) {
  .sign--signup .sign__description {
    max-width: 90%;
  }
  .sign--signup .card {
    padding: 5.5em;
  }
  .sign--signup .sign__form {
    max-width: 70%;
  }
  .sign--signup .signup__signup-focus {
    left: calc(100% + 3.125em);
  }
}

/* Sign In
\*==========================================================================*/
@media (min-width: 48em) {
  .sign--signin .sign__form {
    margin-top: 3.3em;
  }
}
@media (min-width: 62em) {
  .sign--signin .sign__form {
    margin-top: 5.3em;
  }
}
@media (min-width: 80em) {
  .sign--signin .card {
    margin-top: 2em;
  }
  .sign--signin .sign__form {
    margin-top: 5.3em;
  }
}
@media (min-width: 90em) {
  .sign--signin .sign__form {
    max-width: 71%;
    margin-top: 2em;
  }
}
@media (min-width: 120em) {
  .sign--signin .sign__description {
    max-width: 70%;
  }
  .sign--signin .card {
    margin-top: 5.5em;
  }
  .sign--signin .sign__form {
    max-width: 65%;
  }
}

/* Map
\*==========================================================================*/
.section_map {
  max-width: 68em;
  margin-right: auto;
  margin-bottom: 1em;
  margin-left: auto;
}
.section_map .google-maps.is-active {
  height: 500px;
}
.section_map .google-maps.is-active + a {
  display: none;
}
.section_map img {
  width: 100%;
}
@media (min-width: 48em) {
  .section_map {
    margin-bottom: 6em;
  }
}
@media (min-width: 62em) {
  .section_map {
    margin-bottom: 9em;
  }
}

/* Informations
\*==========================================================================*/
.section_infos {
  max-width: 68em;
  margin-right: auto;
  margin-bottom: 1.5em;
  margin-left: auto;
}
.section_infos .col-info:not(:first-child) {
  margin-top: 1em;
}
@media (min-width: 30em) {
  .section_infos .col-info {
    margin-top: 1em;
  }
  .section_infos .col-info:nth-child(1), .section_infos .col-info:nth-child(2) {
    margin-top: 0;
  }
}
@media (min-width: 48em) {
  .section_infos .col-info {
    margin-top: 1em;
  }
  .section_infos .col-info:nth-child(1), .section_infos .col-info:nth-child(2), .section_infos .col-info:nth-child(3), .section_infos .col-info:nth-child(4) {
    margin-top: 0;
  }
}

.infos__row {
  display: flex;
  flex-wrap: wrap;
  justify-items: stretch;
}

.info {
  font-family: "urwgrotesk-lig", "Helvetica Neue", Arial, sans-serif;
  text-align: center;
  background: #f5f6f8;
}
.info a {
  display: inline-block;
  margin-top: 0.5em;
  text-decoration: underline;
}
.info a:focus, .info a:hover {
  text-decoration: none;
}
.info .info-inner {
  padding: 2em;
  background: #f5f6f8;
}
.info .info-title {
  margin-top: 0;
  font-family: "urwgrotesk-lig", "Helvetica Neue", Arial, sans-serif;
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.404ch;
}
.info .info-description {
  margin-bottom: 0;
  line-height: 2em;
  color: #777777;
}
@media (min-width: 30em) {
  .info {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: baseline;
    height: 100%;
  }
  .info .info-title {
    margin-bottom: 2em;
  }
}
@media (min-width: 48em) {
  .info .info-inner {
    padding: 1em;
  }
}
@media (min-width: 62em) {
  .info {
    justify-content: center;
  }
}
@media (min-width: 80em) {
  .info .info-inner {
    padding: 2.5em 1em;
  }
}

/* Aside
\*==========================================================================*/
.main-aside .form_signup {
  padding: 1.5em;
}
.form_signup .aside-title {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.882352941em;
  font-weight: 400;
}
.form_signup .aside-description {
  margin-bottom: 2em;
  color: #777777;
}
.form_signup .aside-description p:last-child {
  margin-bottom: 0;
}
.form_signup .form-field__checkbox {
  margin-bottom: 1em;
}
.form_signup .sign__submit {
  width: 100%;
  margin-top: 2em;
}
@media (min-width: 30em) {
  .main-aside .form_signup {
    padding: 3em;
  }
}
@media (min-width: 80em) {
  .main-aside .form_signup {
    padding: 4em;
  }
}